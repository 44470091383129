import React from "react"

function IdeasGrid({ children }) {
  return (
    <div className={"ideasGrid"}>
      {children}
    </div>
  )
}

export default IdeasGrid